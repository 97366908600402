import styled from "@emotion/styled"

export const NameContainer = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 9rem;
  font-weight: bold;
  letter-spacing: -25px;
  opacity: 0.6;
  display: flex;
  justify-content: center;

  > div {
    position: relative;
    width: 420px;
    height: 280px;
  }

  span {
    mix-blend-mode: color-burn;
    position: absolute;
    :nth-of-type(1) {
      color: ${props => props.colors[0]};
      top: 0;
      left: 0;
    }
    :nth-of-type(2) {
      color: ${props => props.colors[1]};
      left: 15%;
      top: 0;
    }
    :nth-of-type(3) {
      color: ${props => props.colors[2]};
      left: 40%;
      top: 0;
    }
    :nth-of-type(4) {
      color: ${props => props.colors[3]};
      left: 65%;
      top: 0;
    }
    :nth-of-type(5) {
      color: ${props => props.colors[4]};
      left: 15%;
      bottom: 0;
    }
    :nth-of-type(6) {
      color: ${props => props.colors[5]};
      left: 35%;
      bottom: 0;
    }
    :nth-of-type(7) {
      color: ${props => props.colors[6]};
      left: 55%;
      bottom: 0;
    }
  }
`
