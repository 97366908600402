import React, { useState } from "react"
import { NameContainer } from "./style"

const arrayOfColors = [
  "#FF7BAB",
  "#FF7B7B",
  "#C9E93E",
  "#76DACE",
  "#5ABFF8",
  "#7B88FF",
  "#9B54F6",
]

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return [...array]
}

const Name = () => {
  const [colors, setColors] = useState(arrayOfColors)
  const changeColors = () => setColors(shuffle(arrayOfColors))
  return (
    <NameContainer onMouseEnter={changeColors} colors={colors}>
      <div>
        <span>E</span>
        <span>M</span>
        <span onClick={changeColors}>M</span>
        <span>A</span>
        <div />
        <span>R</span>
        <span>O</span>
        <span>E</span>
      </div>
    </NameContainer>
  )
}

export default Name
