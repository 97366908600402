import React from "react"
import { Link } from "gatsby"
import Name from "../components/Name/Name"
import styled from "@emotion/styled"

export const Main = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`

const UnderConstruction = styled.p`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #808080;
`

const Portfolio = () => {
  return (
    <Main>
      <Name />
      <UnderConstruction>
        site under construction, come back soon!
      </UnderConstruction>
      <UnderConstruction>
        (or, send me an email at roe.j.emma@gmail.com)
      </UnderConstruction>
    </Main>
  )
}

export default Portfolio
